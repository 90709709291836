<template>
	<div class="w-100">
		<v-autocomplete
			:id="id"
			:label="label"
			:loading="loading"
			:disabled="disabled"
			:clearable="clearable"
			:items="getUnitList"
			:rules="rules"
			:append-outer-icon="appendOuterIcon"
			item-text="unit"
			item-value="unit"
			:hide-details="hideDetails"
			:return-object="returnObject"
			:multiple="multiple"
			:autofocus="autofocus"
			:readonly="readonly"
			:reverse="reverse"
			outlined
			class="pt-0"
			:class="{
				...contentClass,
				'mt-3': !hideTopMargin,
				'dense-autocomplete': dense,
				'time-picker-autocomplete': timePicker,
			}"
			:menu-props="menuProps"
			v-model="autocompleteinput"
			:placeholder="getPlaceholder()"
			v-on:change="$emit('change', autocompleteinput)"
			v-on:click:append-outer="createDialog = true"
		>
			<template v-slot:no-data>
				<v-list-item>
					<v-list-item-title v-html="`No ${placeholder} Found.`"></v-list-item-title>
				</v-list-item>
			</template>
		</v-autocomplete>
		<Dialog :dialog="createDialog" :dialogWidth="dialogWidth" v-if="createDialog">
			<template v-slot:title>
				<v-layout>
					<v-flex> Manage Unit </v-flex>
					<v-flex class="text-right">
						<v-btn
							tile
							depressed
							color="blue darken-4 text-white"
							small
							class="mx-4"
							v-on:click="createUnitType"
						>
							<span class="font-size-14 font-weight-600"> <v-icon>mdi-plus</v-icon> New Unit</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</template>
			<template v-slot:body>
				<v-container class="py-0">
					<v-form
						ref="unitForm"
						v-model="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateUnitSetting"
					>
						<v-row class="py-0">
							<v-col md="12" class="py-0">
								<v-simple-table>
									<tbody>
										<template v-for="(type, index) in unitList">
											<tr :key="`unit-row-${index}`" class="table-unit-row">
												<td style="border: none" width="95%">
													<TextInput
														hide-details
														:disabled="pageLoading"
														placeholder="Enter Unit"
														:loading="pageLoading"
														:id="`unit-${index}`"
														v-model="type.unit"
														class="mb-3"
														:class="{
															required: !type.unit,
														}"
														:rules="[vrules.required(type.unit, 'Unit')]"
													></TextInput>
												</td>
												<td style="border: none" width="5%">
													<v-tooltip top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-icon
																v-on:click="deleteUnitType(index, type)"
																:disabled="unitList.length == 1 ? true : false"
																color="deep-orange"
																v-bind="attrs"
																v-on="on"
																>mdi-delete</v-icon
															>
														</template>
														<span>Click here to delete</span>
													</v-tooltip>
												</td>
											</tr>
											<tr :key="`unit-index-${index}`" v-if="false">
												<td colspan="2" style="border: none; height: 15px !important"></td>
											</tr>
										</template>
									</tbody>
								</v-simple-table>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</template>
			<template v-slot:action>
				<v-btn
					tile
					depressed
					:disabled="pageLoading"
					class="mx-2 custom-grey-border custom-bold-button"
					v-on:click="createDialog = false"
				>
					Close
				</v-btn>
				<v-btn
					tile
					depressed
					:disabled="pageLoading"
					:loading="pageLoading"
					class="mx-2 custom-grey-border custom-bold-button white--text"
					color="blue darken-4 white--text"
					v-on:click="updateUnitSetting"
				>
					Save
				</v-btn>
			</template>
		</Dialog>
		<DeleteTemplate
			type="Unit"
			:delete-text="deleteText"
			v-on:success="removeUnit"
			v-on:close="deleteDialog = false"
			:delete-dialog="deleteDialog"
			:delete-url="deleteURL"
		>
		</DeleteTemplate>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import TextInput from "@/view/components/TextInput";
import { toSafeInteger, cloneDeep } from "lodash";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";

export default {
	name: "ManageUnits",
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		value: {
			type: [Object, Number, String, Array],
			default: null,
		},
		hideTopMargin: {
			type: Boolean,
			default: false,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		contentClass: {
			type: Object,
			default: null,
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		timePicker: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		reverse: {
			type: Boolean,
			default: false,
		},
		returnObject: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(value) {
				this.autocompleteinput = value;
			},
		},
		createDialog(param) {
			if (param) {
				this.unitList = cloneDeep(this.localDB("measurement_units", []));
				if (this.unitList && this.unitList.length == 0) {
					this.createUnitType();
				}
			}
		},
	},
	data: () => {
		return {
			createDialog: false,
			formValid: true,
			pageLoading: false,
			unitList: [],
			deleteURL: null,
			deleteId: null,
			deleteDialog: false,
			deleteIndex: null,
			deleteText: null,
			autocompleteinput: null,
			menuProps: {},
		};
	},
	methods: {
		getPlaceholder() {
			return `Select ${this.placeholder}`;
		},
		updateUnitSetting() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.unitForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.unitForm.validate()) {
				return false;
			}

			_this.pageLoading = true;

			ApiService.setHeader();
			ApiService.post(`measurement-unit`, { units: _this.unitList })
				.then(({ data }) => {
					_this.$store.dispatch(SET_LOCAL_DB);
					_this.createDialog = false;
					_this.$emit("success", data);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.createDialog = false;
					_this.pageLoading = false;
				});
		},
		createUnitType() {
			this.unitList.push({
				id: null,
				unit: "",
			});

			this.$nextTick(() => {
				const focus_element = `unit-${this.unitList.length - 1}`;
				document.getElementById(focus_element).focus();
			});
		},
		deleteUnitType(index /*  data */) {
			//console.log(data);
			// if (this.unitList.length > 1) {
			// 	if (data && data.id) {
			// 		this.deleteDialog = true;
			// 		this.deleteId = data.id;
			// 		this.deleteIndex = index;
			// 		this.deleteText = data.unit;
			// 		this.deleteURL = "measurement-unit/" + data.id;
			// 	} else {
			// 		this.unitList.splice(index, 1);
			// 	}
			// }
			if (this.unitList.length > 1) {
				this.unitList.splice(index, 1);
			}
		},
		removeUnit() {
			if (this.deleteIndex) {
				this.unitList.splice(this.deleteIndex, 1);
				this.deleteIndex = null;
				this.deleteDialog = false;
				this.$store.dispatch(SET_LOCAL_DB);
				this.$emit("success", this.unitList);
			}
		},
	},
	components: {
		Dialog,
		DeleteTemplate,
		TextInput,
	},
	mounted() {
		this.unitList = cloneDeep(this.localDB("measurement_units", []));
		this.autocompleteinput = this.value;
		if (this.dense) {
			this.menuProps.contentClass = "dense-autocomplete-list";
		}
		if (this.timePicker) {
			this.menuProps.contentClass = "time-picker-autocomplete-list";
		}
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			if (document.body.clientWidth > 992) {
				return 800;
			}
			return toSafeInteger((document.body.clientWidth / 100) * 90);
		},
		getUnitList() {
			return this.localDB("measurement_units", []);
		},
	},
};
</script>
